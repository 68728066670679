// Generated by Framer (46f1e3d)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["byBGCMu1H"];

const serializationHash = "framer-JozBd"

const variantClassNames = {byBGCMu1H: "framer-v-24lrbw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "byBGCMu1H", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-24lrbw", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"byBGCMu1H"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7UGx1cyBKYWthcnRhIFNhbnMtNTAw", "--framer-font-family": "\"Plus Jakarta Sans\", \"Plus Jakarta Sans Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.5px", "--framer-line-height": "1.5em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-1of0zx5, rgb(255, 255, 255))"}}>Get in touch</motion.h2></React.Fragment>} className={"framer-1nwhbjm"} fonts={["GF;Plus Jakarta Sans-500"]} layoutDependency={layoutDependency} layoutId={"E0O3NUr15"} style={{"--extracted-1of0zx5": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JozBd.framer-1244bw1, .framer-JozBd .framer-1244bw1 { display: block; }", ".framer-JozBd.framer-24lrbw { height: 43px; overflow: hidden; position: relative; width: 182px; will-change: var(--framer-will-change-override, transform); }", ".framer-JozBd .framer-1nwhbjm { flex: none; height: auto; left: 0px; max-width: 100%; overflow: visible; position: absolute; right: 0px; top: 51%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 43
 * @framerIntrinsicWidth 182
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerp4yHlw4xg: React.ComponentType<Props> = withCSS(Component, css, "framer-JozBd") as typeof Component;
export default Framerp4yHlw4xg;

Framerp4yHlw4xg.displayName = "button";

Framerp4yHlw4xg.defaultProps = {height: 43, width: 182};

addFonts(Framerp4yHlw4xg, [{explicitInter: true, fonts: [{family: "Plus Jakarta Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_m07NTxXUEKi4Rw.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})